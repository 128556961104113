import { mapGetters } from "vuex";
import releaseHeaders from "@/assets/table/production/releaseColorHeader";
import ReleaseConfirmDialog from "@/components/pages/production/docForm/release/ReleaseConfirmDialog.vue";
import releaseAppearanceHeaders from "@/assets/table/production/releaseAppearanceHeaders";
import PrintReleaseDialog from "@/components/organisms/VDialog/PrintReleaseDialog.vue";
import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import ClientComboBox from "@/components/atoms/VComboBox/DenseDialogClientComboBox";
import DocForm from "@/components/organisms/docDataForm/docForm.vue";
import SalesSurcharge from "@/components/organisms/VDialog/SalesSurcharge";
import StockChangeDialog from "@/components/organisms/VDialog/StockChangeDialog";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";
import { getStockItems, getStockColors } from "@/api/production";
export default {
  components: {
    ReleaseConfirmDialog,
    PrintReleaseDialog,
    settingFormDialog,
    ClientComboBox,
    DocForm,
    SalesSurcharge,
    StockChangeDialog,
    DenseSubItemComboBox,
  },
  data() {
    return {
      menu_date_delivery: false,
      dialog_bak_release: false,
      deadlineRelease: {},
      bakupData: {},
      useColorList: [],
      releaseHeaders,
      releaseAppearanceHeaders,
      releaseAppearanceColor: {},
      memo: "",
      releaseStyleColorList: [],
      selectType: "출고의뢰서",
      stockItems: [],
      stockColorList: [],

      isOverSize: false,
      selectRelease: {
        item: {},
        order: {},
        liaison: {},
        place_forward: {},
        place_receive: {},
      },
      stockKind: [
        {
          value: 1,
          label: "전 구좌 사용",
        },
        {
          value: 2,
          label: "현 구좌 사용",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      form: "getProductionForm",
      release: "getReleaseForm",
      docList: "getProductionDoc",
      itemList: "getItemList",
      clientList: "getUseClientList",
      colorList: "getProductionColor",
      commaColor: "getCommaColor",
      attachmentList: "getAttachmentList",

      colorTotal: "getProductionColorTot",
      selectColorList: "getReleaseColorList",
      beforVersion: "getBeforProductionData",
      loginUser: "getLoginUser",
      purchase: "getPurchasCacul",
      sales: "getSalesCacul",
      isDocDataBtn: "getIsDocDataBtn",
      docDataList: "getDoctDataList",
    }),

    commaColors() {
      const colorList = this.copyColorList.map((x) => {
        return {
          ...x,
          quantity: this.usingComma(x.quantity),
        };
      });

      return colorList;
    },

    purUnitSign() {
      const unitSign =
        this.release.requestDoc.unit_sign === "kg"
          ? [
              this.release.requestDoc.unit_sign,
              this.release.requestDoc.weight_unit_sign,
            ]
          : this.$store.state.unit_sign;

      return unitSign;
    },

    copyColorList() {
      return this.copyData(this.colorList);
    },

    // 출고 단위
    releaseUnitSigns() {
      return this.release.requestDoc.unit_sign &&
        this.release.requestDoc.unit_sign === "kg"
        ? [...this.$store.state.unit_sign, "kg"]
        : this.$store.state.unit_sign;
    },

    //출고 수량 단위에따른 단위
    colorConvertSign() {
      return this.release.unit_sign === "kg"
        ? this.release.weight_unit_sign
        : "kg";
    },

    bakDialog() {
      return this.release.history
        ? this.release.history.length > 0
          ? true
          : false
        : false;
    },

    formActivation() {
      return this.loginUser.grade >= 2
        ? (this.release.status === "40" ||
            this.release.status === "30" ||
            !this.release._id) &&
          this.release.status !== "50"
          ? false
          : true
        : this.release.status === "40" ||
          this.release.status === "20" ||
          this.release.status === "30" ||
          (!this.release._id && this.release.status !== "50")
        ? false
        : true;
    },

    formReleaseDisabled() {
      return this.release.status === "99" || this.release.status === "90"
        ? true
        : this.release.isModify
        ? this.formActivation
        : !this.release.isModify;
    },

    formSalesDisabled() {
      return this.release.status === "99" || this.release.status === "90"
        ? true
        : this.release.isModify
        ? this.release.salesBtn
          ? this.formActivation
          : true
        : true;
    },

    fabricUnitPriceActivation() {
      return this.release.fabricBtn
        ? this.formActivation
        : this.formSalesDisabled;
    },

    cancelBtn() {
      return this.release.status_sales && !this.release.status_purchase
        ? false
        : this.loginUser.grade >= 2
        ? (this.release.status === "40" || this.release.status === "90") &&
          this.manufactureList.filter(
            (x) =>
              x.release === this.release.id &&
              x.kind === "appearance" &&
              x.status !== "99",
          ).length === 0
        : this.release.status === "40";
    },

    dialogWidth() {
      return this.isDocDataBtn ? 1600 : 1000;
    },

    salesExchange() {
      return this.sales.eschamge_price
        ? `  (₩${this.sales.eschamge_price})`
        : "";
    },
    purchaseExchange() {
      return this.purchase.eschamge_price
        ? `(₩${this.purchase.eschamge_price})`
        : "";
    },

    purchaseAmout() {
      return this.decimalCacul(
        (this.purchase.quantity ? this.purchase.quantity : 0) *
          this.purchase.unit_price,
      );
    },

    // 출고의뢰서가 참조한 출고처 문서의 따라 문서의 종류를 반환 하는 함수
    // reqDocType() {
    //   const kind = this.release.requestDoc.kind;
    //   if (kind === 7 || kind === 18 || kind === 12) return "manufacture";
    //   if (kind === 11) return "appearance";
    //   if (kind === 12) return "preRelease";
    //   if (kind === 0) return "createStock";
    // },

    formTitleRelease() {
      return this.release.id === undefined
        ? "출고의뢰서 신규 작성"
        : this.release.status === "00"
        ? "마감 출고의뢰서"
        : this.release.status === "10"
        ? "출고의뢰서 취소 요청"
        : this.release.status === "20"
        ? "출고의뢰서 승인 요청"
        : this.release.status === "30"
        ? "반려 출고의뢰서"
        : this.release.status === "40"
        ? "수정 출고의뢰서"
        : this.release.status === "50"
        ? "출고의뢰서 수정 요청"
        : this.release.status === "90"
        ? "출고의뢰서 취소 요청"
        : this.release.status === "99"
        ? "취소 출고의뢰서"
        : "출고의뢰서";
    },

    wherHouseList() {
      const list = this.copyData(this.clientList);

      list.unshift({
        name: "미지정",
      });
      return list;
    },

    currencyLable() {
      return this.release.currency_sign === "₩"
        ? !this.isDocDataBtn
          ? `${this.release.fabricBtn ? "매입" : "매출"}단가`
          : "단가"
        : !this.isDocDataBtn
        ? `${this.release.fabricBtn ? "매입" : "매출"}단가/환율`
        : "단가 환율";
    },

    purColorList() {
      return this.release.requestDoc && this.release.requestDoc.kind === 12
        ? this.release.requestDoc.remainList
        : [];
    },

    stockClientName() {
      const progress = this.form.progress.find(
        (x) => x.id === this.release.requestDoc.id,
      );

      return this.release.requestDoc.kind === 17
        ? "재고 출고"
        : this.release.requestDoc.kind === 0
        ? "재고 등록"
        : progress
        ? progress.log
        : "삭제 된 외관 검사서";
    },

    purNotVatCheckBoxClass() {
      return !this.release.purBtn || this.formReleaseDisabled
        ? "checkBoxVatLabelDisabledColor"
        : "vatPurchaseLabel";
    },
    salesNotVatCheckBoxClass() {
      return this.formSalesDisabled
        ? "checkBoxVatLabelDisabledColor"
        : "vatSalesLabel";
    },

    purNotVatBtnClass() {
      return !this.release.purBtn || this.formReleaseDisabled
        ? ""
        : this.release.isPurNotVat === false
        ? "vatBtnGreenStyle"
        : this.release.isPurNotVat === true
        ? "vatBtnRedStyle"
        : "vatBtnGreenStyle";
    },

    salesNotVatBtnClass() {
      return this.formSalesDisabled
        ? ""
        : this.release.isSalesNotVat === false
        ? "vatBtnGreenStyle"
        : this.release.isSalesNotVat === true
        ? "vatBtnRedStyle"
        : "vatBtnGreenStyle";
    },
  },

  methods: {
    //////////////////////////////////////// 공통 기능 /////////////////////////////////////
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },

    // setProductionColor color 데이터 전달
    setProductionColor(colorList) {
      this.$store.commit("setProductionColor", colorList);
    },

    //////////////////////////////////////// DOC DATA PARSING FUNCTION /////////////////////////////////////

    //////////////////////////////////////// 출고의로시 입력 기능 /////////////////////////////////////
    setCommaColor(colorList) {
      this.$store.commit("setCommaColor", colorList);
    },

    setColorList(colorList) {
      this.$store.commit("setProductionColor", colorList);
    },

    // 컬러의 길이를 무게로 또는 무게를 길이로 변환 하여 반환 하는 함수
    weightToLength(value) {
      return this.weightCacul(
        value,
        this.release.unit_sign,
        this.release.weight_convert_unit,
        this.release.weight_sign,
      );
    },

    //    // 입력 index 와 color 종류 에 따라 수량을 무게로 바꾸는 함수
    quantityToWeight(index, kind) {
      let value = this.colorList[index][kind];
      if (
        this.release.unit_sign !== "kg" &&
        this.release.unit_sign !== this.release.weight_unit_sign
      )
        value = +this.unitChange(this.release.weight_unit_sign, value);

      return this.usingComma(this.weightToLength(value));
    },

    changeReleaseTypeFabric() {
      if (this.release.fabricBtn) {
        this.$store.dispatch("SET_RELEASE_FABRIC_COLORS");
        this.$store.dispatch("SET_FABRIC_COLOR");
      } else {
        this.$store.commit("setReleaseColorList");
        this.release.requestDoc = { kind: 7 };
        this.$store.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
      }
      this.$store.dispatch("INIT_RELEASE_DOC_CALCULATE");
      this.release.salesBtn = false;
      this.release.purchaseUnitPrice = null;
      this.release.sales_unit_price = "";
    },

    //출고의뢰서
    incomeChange(kind) {
      if (kind === 1) this.release.basicBtn = !this.release.basicBtn;
      if (kind === 2) this.release.salesBtn = !this.release.salesBtn;
      if (kind === 3) this.release.appenBtn = !this.release.appenBtn;
      if (kind === 4) this.release.preBtn = !this.release.preBtn;
      if (kind === 5) this.release.stockBtn = !this.release.stockBtn;

      if (kind !== 1) this.release.basicBtn = false;
      if (kind !== 2) this.release.salesBtn = false;
      if (kind !== 3) this.release.appenBtn = false;
      if (kind !== 4) this.release.preBtn = false;
      if (kind !== 5) this.release.stockBtn = false;

      this.release.purBtn =
        this.release.appenBtn || this.release.basicBtn ? false : true;

      if (!this.release.salesBtn) {
        delete this.release.place_sales;
        delete this.release.sales_unit_price;
      }

      if (
        this.release.requestDoc &&
        (this.release.requestDoc.kind === 11 ||
          this.release.requestDoc.kind === 12 ||
          this.release.requestDoc.kind === 17)
      )
        this.appearanceCheck();

      if (this.release.salesBtn) {
        this.release.place_sales = this.wherHouseList.find(
          (x) => x._id === this.form.client,
        );

        this.release.surcharge_unit = "";
        this.release.currency_sign = this.form.eschamge_sign;
        this.inputUnitPrice(
          this.form.sales_unit_price ? +this.form.sales_unit_price : "",
        );
        this.salesSurcharge(
          this.form.sales_surcharge ? +this.form.sales_surcharge : "",
        );
      } else delete this.release.surcharge_unit;

      if (this.release.salesBtn && this.release.fabricBtn) {
        this.release.fabricBtn = false;
        this.release.sales_unit_price = "";
        this.$store.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
      }

      this.calculationTotal();

      if (!this.release.stockBtn && this.release.requestDoc.kind === 0) {
        this.release.requestDoc = { kind: 7 };
        this.$store.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
      }

      if (!this.release.purBtn) this.release.isPurNotVat = this.release.purBtn;
      if (!this.release.salesBtn) this.release.isSalesNotVat = false;

      this.$store.dispatch("SET_DOC_LIST", this.release.salesBtn);
    },

    appearanceCheck() {
      if (this.release.purBtn && this.release.salesBtn) return;
      this.release.requestDoc = { kind: 7 };
      this.$store.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
    },

    inputUnitPrice(price) {
      const commaPrice = this.usingComma(price);
      if (this.release.salesBtn) this.release.sales_unit_price = commaPrice;
      if (this.release.fabricBtn) this.release.purchaseUnitPrice = commaPrice;
      this.cacluationAmountAndQuantity();
    },

    cacluationAmountAndQuantity() {
      if (this.release.fabricBtn) this.fabricPriceAndQuantityCaculation();
      else this.calculationTotal();
    },

    salesSurcharge(data) {
      this.release.surcharge_unit = this.usingComma(this.parsingNum(data));
      this.calculationTotal();
    },

    salesEschamge(data) {
      this.release.eschamge = this.usingComma(data);
      this.calculationTotal();
    },

    //매입 매출 정산 금액 계산
    calculationTotal() {
      if (
        this.release.purBtn &&
        this.release.requestDoc.kind !== 12 &&
        this.release.requestDoc.kind !== 17 &&
        this.release.requestDoc.kind !== 0
      )
        this.$store.commit(
          "setPurchasCacul",
          this.release.requestDoc.kind === 7 ||
            this.release.requestDoc.kind === 18
            ? this.manufactureCaculate()
            : this.appearanceCaculate(),
        );

      if (!this.release.purBtn) this.$store.commit("setPurchasCacul");

      if (this.release.salesBtn)
        this.$store.dispatch("CALCULATE_RELEASE_SALES");
      if (!this.release.salesBtn) this.$store.commit("setSalesCacul");
    },

    fabricPriceAndQuantityCaculation() {
      const price = this.parsingNum(this.release.purchaseUnitPrice);
      const quantity = this.parsingNum(this.commaColor[0].quantity);
      const purchase = {
        status: "40",
        memo: this.purchase.memo ? this.purchase.memo : "", // 정산, 마감 메모
        total_amount: price * quantity,
        surcharge_price: 0, // surcharge (제직, 건 당) 합산
        color_surcharge_price: 0, // surcharge(염색, 컬러당) 합산
        unit_sign: this.release.unit_sign, // 매입 수량 단위
        unit_price: price, // 매입 단가
        currency_sign: this.release.currency_sign, // 매입 단가 단위
        quantity: quantity,
        color_list: [
          {
            name: this.commaColor[0].name,
            style: this.commaColor[0].style,
            total_amount: price * quantity,
            quantity,
          },
        ],
      };

      this.$store.commit("setPurchasCacul", purchase);
      this.$store.dispatch("CALCULATE_COLOR_TOTAL", purchase.color_list);
      this.release.color_list = this.copyData(purchase.color_list).map((x) => {
        const color = {
          name: x.name,
          style: x.style,
          quantity: x.quantity,
          indexColorName: x.indexColorName,
        };
        return color;
      });
    },

    //colorList 입력
    setColorData(i, data) {
      const colorList = this.copyColorList;
      if (colorList[i].color_surcharge_price)
        this.selectColorList.color.find(
          (x) => x.indexColorName === colorList[i].indexColorName,
        ).color_surcharge_price.is_used = false;

      const style = colorList[i].style;
      colorList[i] = {};
      const color = this.copyData(colorList[i]);
      color.name = data.name;
      color.quantity = data.quantity;
      color.style = style;
      color.isSuccess = true;
      color.indexColorName = data.indexColorName;

      if (this.release.requestDoc.unit_sign !== this.release.unit_sign)
        color.quantity = Math.round(
          color.quantity *
            (this.release.requestDoc.unit_sign === "y" ? 0.914 : 1.094),
        );

      if (data.color_surcharge_unit)
        color.color_surcharge_unit = data.color_surcharge_unit;

      if (data.color_surcharge_price && !data.color_surcharge_price.is_used) {
        color.color_surcharge_price = data.color_surcharge_price.price;
        data.color_surcharge_price.is_used = true;
      }

      color.purchQuantity = data.quantity;

      colorList[i] = color;

      this.setProductionColor(colorList);
      this.calculationTotal();
      this.$store.dispatch("CALCULATE_COLOR_TOTAL", this.colorList);
    },

    setFabricColorData(i, data) {
      this.commaColor[i] = this.copyData(data);
      this.commaColor[i]._id = i;
    },

    //color 삭제
    deleteColorData(i) {
      if (this.colorList[i].color_surcharge_price) {
        this.selectColorList.color.find(
          (x) => x.indexColorName === this.colorList[i].indexColorName,
        ).color_surcharge_price.is_used = false;
      }

      if (this.colorList[i].name) {
        this.colorList[i] = {};
        this.commaColor[i] = {};
      } else this.deleteList(i);

      this.calculationTotal();
      this.$store.dispatch("CALCULATE_COLOR_TOTAL", this.colorList);
    },

    fabriceQuantityInit() {
      this.commaColor[0].quantity = "";
      this.colorList[0].quantity = 0;
      this.cacluationAmountAndQuantity();
    },

    deleteList(i) {
      const colorList = this.copyColorList;
      colorList[i] = {};
      this.setColorList(colorList);
    },

    // colorList row 추가
    pluseColorRow() {
      this.colorList.push({});
    },

    appearanceColorCalculate(i, kind, data) {
      const color = this.colorList[i];
      const commaColor = this.commaColor[i];

      kind === "success"
        ? (color.success_quantity = this.parsingNum(data))
        : (color.fail_quantity = this.parsingNum(data));

      if (color.success_quantity < 0 && kind === "success")
        color.success_quantity = "";
      if (color.fail_quantity < 0 && kind === "fail") color.fail_quantity = "";

      kind === "success"
        ? (color.remaing_quantity =
            color.fix_remaing_quantity - color.success_quantity)
        : (color.remaing_fail_quantity =
            color.fix_remaing_fail_quantity - color.fail_quantity);

      if (color.remaing_quantity < 0) {
        color.remaing_quantity = color.fix_remaing_quantity;
        color.success_quantity = "";
      }

      if (color.remaing_fail_quantity < 0) {
        color.remaing_fail_quantity = color.fix_remaing_fail_quantity;
        color.fail_quantity = "";
      }

      if (kind === "success")
        commaColor.success_quantity = this.usingComma(color.success_quantity);
      if (kind === "fail")
        commaColor.fail_quantity = this.usingComma(color.fail_quantity);

      this.calculationTotal();
      this.$store.dispatch("CALCULATE_APPEARANCE_COLOR_TOTAL", this.colorList);
    },

    purchaseColorCalculate(i, data) {
      const color = this.colorList[i];
      color.success_quantity = this.parsingNum(data);

      const purColor = this.purColorList[color.pur_index];
      const num = this.decimalCacul(
        purColor.success_quantity -
          this.colorList
            .filter((x) => x.name && x.pur_index === color.pur_index)
            .reduce((r, c) => r + +c.success_quantity, 0),
      );

      if (num < 0) {
        // purColor.remaing_quantity = purColor.success_quantity;
        color.success_quantity = "";
        purColor.remaing_quantity =
          purColor.success_quantity -
          this.colorList.reduce(
            (r, c) =>
              r +
              (c.success_quantity && color.pur_index === c.pur_index
                ? +c.success_quantity
                : 0),
            0,
          );
        this.snackbarOn("출고수량이 잔여수량 보다 큽니다.");
      } else purColor.remaing_quantity = num;

      this.calculationTotal();
      this.$store.dispatch("CALCULATE_PRE_COLOR_TOTAL", this.colorList);
    },

    //재고 출고 수량 입력
    stockColorCalculate(i, num) {
      this.colorList[i].success_quantity = this.parsingNum(num);

      this.calculationTotal();
      this.$store.dispatch("CALCULATE_STOCK_COLOR_TOTAL", this.colorList);
    },

    // 단위 변경
    unitSignChange() {
      // const colorList = this.colorList.map((x) => {
      //   return {
      //     ...x,
      //     quantity:
      //       x.quantity &&
      //       (
      //         +x.quantity * (this.release.unit_sign === "y" ? 1.094 : 0.914)
      //       ).toFixed(0),
      //   };
      // });

      // this.setProductionColor(colorList);
      this.cacluationAmountAndQuantity();
      if (!this.release.fabricBtn)
        this.$store.dispatch("CALCULATE_COLOR_TOTAL", this.colorList);
    },

    isSuccess(color) {
      return !color.isSuccess ? true : this.formReleaseDisabled;
    },

    isFaill(color) {
      return !color.isFaill ? true : this.formReleaseDisabled;
    },

    isPurColor(color) {
      return !color.name ? true : this.formReleaseDisabled;
    },

    inputPlace(data, type) {
      this.release[type === "sales" ? "place_sales" : "place_receive"] =
        data.name === "미지정" ? "" : data;
      if (type === "wherehouse") {
        this.release.place_receive_memo = "";

        this.release.place_receive_memo = data.name
          ? `${data.name}${data.address ? `/${data.address}` : ""}${
              data.tel ? `/${this.getPhoneMask(data.tel)}` : ""
            } `
          : "";
      }
    },

    purQuantityUnitChange() {
      const colorList = this.copyColorList.map((x) => {
        return {
          ...x,
          success_quantity: "",
        };
      });

      this.setColorList(colorList);
      this.$store.dispatch("PUR_COLOR_UNIT_CHANGE");
      this.calculationTotal();
      this.$store.dispatch("CALCULATE_PRE_COLOR_TOTAL", colorList);
    },

    // 선매입 매출 출고 color 선택할경우
    purColorSet(data, i) {
      const colorList = this.copyColorList;
      const color = {
        name: data.name,
        indexColorName: data.indexColorName,
        style: data.style ? data.style : "",
        success_quantity: "",
        pur_index: data.index,
      };

      colorList[i] = color;
      this.setColorList(colorList);
      this.$store.dispatch("CALCULATE_PRE_COLOR_TOTAL", this.colorList);
    },

    purColorDelete(i) {
      if (this.colorList[i].name) {
        this.purColorList[this.colorList[i].pur_index].remaing_quantity =
          +this.purColorList[this.colorList[i].pur_index].remaing_quantity +
          +this.colorList[i].success_quantity;
      }

      this.deleteList(i);

      this.calculationTotal();
      this.$store.dispatch("CALCULATE_PRE_COLOR_TOTAL", this.colorList);
    },

    stockColorDelete(i) {
      this.deleteList(i);
      this.calculationTotal();
      this.$store.dispatch("CALCULATE_STOCK_COLOR_TOTAL", this.colorList);
    },

    stockColorData(i) {
      return `${this.usingComma(
        this.copyData(this.colorList[i].success_quantity),
      )}`;
    },

    //재고 수량 color 선택할경우
    stockColorSet(data, i) {
      const stock = this.copyData(data);
      const colorList = this.copyColorList;
      const color = {
        order: stock.order,
        item: stock.item,
        name: stock.name,
        remain_quantity: stock.remain_quantity,
        success_quantity: "",
        stockUnitStign: stock.unit_sign,
        stockId: stock.id,
        kind: stock.kind,
      };

      if (color.stockUnitStign !== this.release.unit_sign)
        data.remain_quantity = this.unitChange(
          this.release.unit_sign,
          color.remain_quantity,
        );

      colorList[i] = color;

      this.setColorList(colorList);

      this.$store.dispatch("CALCULATE_STOCK_COLOR_TOTAL", colorList);
    },

    remainStockQuantity(i) {
      if (!this.colorList[i]) return;
      const color = this.colorList[i];

      const remaiQuantity =
        color.stockUnitStign !== this.release.unit_sign
          ? this.unitChange(this.release.unit_sign, color.remain_quantity)
          : color.remain_quantity;

      const quantity =
        +remaiQuantity -
        this.colorList.reduce((r, c) => {
          if (c.stockId === color.stockId) r = r + +c.success_quantity;
          return r;
        }, 0);

      if (quantity < 0) {
        this.colorList.forEach((x, colorIndex) => {
          if (x.stockId === color.stockId)
            this.stockColorCalculate(colorIndex, 0);
        });
        return this.snackbarOn("출고 수량이 재고 수량보다 많습니다.");
      } else
        return `${this.usingComma(
          isNaN(quantity) ? remaiQuantity : quantity,
        )} ${this.release.unit_sign}`;
    },

    inputStyle(i) {
      this.colorList[i].style = this.commaColor[i].style;
      this.$store.dispatch("CALCULATE_RELEASE_SALES");
    },

    styleChange(style, i) {
      const colorList = this.copyColorList;
      colorList[i].style = style;
      this.setColorList(colorList);
    },

    colorSurchargeDialog() {
      this.$store.commit("setIsSalesSurcharge");
    },

    // 출고처 재고 수량 선택할경우 재고의 item No 를 신규 호출 하는 함수
    async setStockItems() {
      try {
        const result = await getStockItems(this.release.company);
        this.stockItems = result.data;
      } catch (e) {
        console.log(e);
      }
    },

    // 출고처 를 재고 수량 을 선택 할 경우
    setStocksDoc() {
      this.setStockItems();
      this.release.item = "";
      const colorList = Array.from({ length: this.colorList.length }, () => {
        return {};
      });
      this.$store.commit("setProductionColor", colorList);
      this.$store.commit("setCommaColor", colorList);
      this.$store.dispatch("INIT_RELEASE_DOC_CALCULATE");
    },

    setReqDoc() {
      if (this.release.requestDoc.kind !== 17)
        this.$store.dispatch("SET_RELEASE_REQUEST");
      else this.setStockItems();
    },

    async changeStock() {
      await this.$store.dispatch("SELECT_ORDER", this.form);
      const count = this.$store.state.selectOrder.release.filter(
        (x) =>
          x.status !== "40" &&
          x.status !== "99" &&
          x.release === this.release.id,
      ).length;
      this.$store.commit("setSelectOrder");

      if (count) {
        this.snackbarOn(
          "현재 출고 요청 중인 매출 출고가 있습니다. 전체 승인 후 재고 수량으로 변환해주시기 바랍니다.",
        );
      } else {
        this.$store.commit("setIsStockDialog");
      }
    },

    // stock 상태값이 수정 가능 상태 여부 전달
    isModyfyableStockColor(color) {
      return !(this.formReleaseDisabled || this.isStockStatus(color));
    },

    //stock 상태값에 따라 Boolean 값을 전닥 (40:true, 00:false)
    isStockStatus(color) {
      return (
        color.name && this.$store.state.stockList[color.index].status === "40"
      );
    },

    ///////// /////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////// 출고의로시 저장 기능 /////////////////////////////////////

    refer() {
      this.release.refer = true;
      this.$store.commit("setProductionConfirm");
    },

    close() {
      if (!this.release._id || this.release.status === "40") {
        this.$store.commit("setResetCheck");
      } else {
        this.$store.commit("setReleaseForm");
      }
    },

    inputColorCalcul(i, data) {
      const num = data ? this.parsingNum(data) : "";

      const color = this.colorList[i];
      // const commaColor = this.commaColor[i];
      color.quantity = num;
      // commaColor.quantity = this.usingComma(num);

      if (this.colorList[i].quantity < 0) color.quantity = "";
      // commaColor.quantity = "";

      this.calculationTotal();
      this.$store.dispatch("CALCULATE_COLOR_TOTAL", this.colorList);
    },

    inputFabricColorQuantity(quantity) {
      this.commaColor[0].quantity = this.usingComma(quantity);
      this.cacluationAmountAndQuantity();
    },

    async stockItemChange() {
      try {
        const result = await getStockColors(
          this.release.company,
          this.release.item.name,
        );

        this.stockColorList = result.data;
      } catch (e) {
        console.log(e);
      }
    },

    stockColorInput(txt, i) {
      this.colorList[i].name = txt;
      this.commaColor[i].name = txt;
      this.commaColor[i].isSuccess = !!txt;
    },

    async presetChange(preset) {
      await this.$store.dispatch("RELEASE_PRESET", preset);

      if (
        this.release.requestDoc.kind === 17 ||
        this.release.requestDoc.kind === 0
      )
        this.release.requestDoc = this.docList[0];
      this.calculationTotal();
    },

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////// 출고의로시 매입 계산 /////////////////////////////////////
    manufactureCaculate() {
      const requestDoc = JSON.parse(JSON.stringify(this.release.requestDoc));
      const purchase = {
        status: "40", // 매입 정산, 마감 상태
        memo: this.release._id ? this.release.purchase.memo : "", // 정산, 마감 메모
        total_amount: 0, // 총 금액
        surcharge_price: 0, // surcharge (제직, 건 당) 합산
        color_surcharge_price: 0, // surcharge(염색, 컬러당) 합산
        weavig_surcharge_unit: 0,
        weavig_sucharge_price: 0,
        unit_sign: requestDoc.unit_sign, // 매입 수량 단위
        unit_price: requestDoc.unit_price, // 매입 단가
        currency_sign: requestDoc.currency_sign, // 매입 단가 단위
        color_list: [],
      };

      // 참조 의뢰서 출고 단위가 kg 이고 출고 단위가 y 또는 m 일 경우 kg 으로 변환 해주는 함수
      const quantityChangeToKg = (inputQuantity) => {
        let quantity = inputQuantity;
        if (this.release.unit_sign !== this.release.weight_unit_sign)
          quantity = +this.unitChange(
            this.release.weight_unit_sign,
            inputQuantity,
          );

        return this.weightToLength(quantity);
      };

      const quantityChange = (inputQuantity) => {
        let quantity;
        if (this.release.unit_sign === "kg") {
          quantity = this.weightToLength(inputQuantity);
        } else {
          quantity =
            requestDoc.unit_sign === "kg"
              ? quantityChangeToKg(inputQuantity)
              : (
                  +inputQuantity *
                  (requestDoc.unit_sign === "y" ? 1.094 : 0.914)
                ).toFixed(0);
        }

        return quantity;
      };

      if (requestDoc.surcharge_unit && !requestDoc.surcharge_unit.is_used) {
        purchase.surcharge_price += requestDoc.surcharge_unit.price;
        purchase.weavig_surcharge_unit = requestDoc.surcharge_unit.price;
      }

      // db 저장 변경시 purchase.surcharge_price =>  purchase.surcharge_unit 변경
      if (
        this.release.id &&
        this.release.purchase.surcharge_price &&
        requestDoc.surcharge_unit
      ) {
        purchase.surcharge_price += this.release.purchase.surcharge_price;
        purchase.weavig_surcharge_unit = this.release.purchase.surcharge_price;
      }

      this.colorList.forEach((x) => {
        if (x.name && x.quantity) {
          const color = this.copyData(x);
          color.quantity = +x.quantity;

          if (requestDoc.unit_sign !== this.release.unit_sign)
            color.quantity = quantityChange(color.quantity);

          color.total_amount = this.decimalCacul(
            color.quantity * requestDoc.unit_price,
          );

          if (x.color_surcharge_unit) {
            color.color_surcharge_unit = this.decimalCacul(
              color.quantity * x.color_surcharge_unit,
            );
            purchase.color_surcharge_price += color.color_surcharge_unit;
          }

          if (x.color_surcharge_price)
            purchase.color_surcharge_price += x.color_surcharge_price;

          if (requestDoc.sucharge_price) {
            color.surcharge_price = this.decimalCacul(
              color.quantity * requestDoc.sucharge_price,
            );
            purchase.surcharge_price += color.surcharge_price;
            purchase.weavig_sucharge_price += color.surcharge_price;
          }

          if (requestDoc.eschamge) purchase.eschamge = requestDoc.eschamge;

          purchase.color_list.push(color);
          purchase.total_amount += color.total_amount;
        }
      });
      purchase.total_amount +=
        purchase.color_surcharge_price + purchase.surcharge_price;
      purchase.quantity = purchase.color_list.reduce(
        (r, c) => r + +c.quantity,
        0,
      );
      if (requestDoc.currency_sign !== "₩") {
        purchase.eschamge = requestDoc.eschamge;
        purchase.eschamge_price = purchase.total_amount * requestDoc.eschamge;
      }

      this.purchaseDecimalCacul(purchase);

      return purchase;
    },

    appearanceCaculate() {
      const manufacture = this.copyData(
        this.form.manufacture.find(
          (x) => x.id === this.release.requestDoc.manufacture,
        ),
      );

      // console.log("requDoc =", this.release.requestDoc);
      // console.log("select manufacture =", manufacture);

      const purchase = {
        status: "40", // 매입 정산, 마감 상태
        memo: this.release._id ? this.release.purchase.memo : "", // 정산, 마감 메모
        total_amount: 0, // 총 금액
        surcharge_price: 0, // surcharge (제직, 건 당) 합산
        color_surcharge_price: 0, // surcharge(염색, 컬러당) 합산
        unit_sign: manufacture.unit_sign, // 매입 수량 단위
        unit_price: manufacture.unit_price, // 매입 단가
        currency_sign: manufacture.currency_sign, // 매입 단가 단위
        color_list: [],
      };
      if (manufacture.surcharge_unit && !manufacture.surcharge_unit.is_used) {
        purchase.surcharge_price = manufacture.surcharge_unit.price;
        manufacture.surcharge_unit.is_used = true;
      }
      this.colorList.forEach((x) => {
        // console.log("===========");
        // console.log("request id=", this.release.requestDoc.id);
        // console.log("manufacture id=", this.release.requestDoc.manufacture);
        // console.log("select manufacture id=", manufacture.id);
        if (x.name) {
          const manufactureColor = x.indexColorName
            ? manufacture.color_list.find(
                (y) => y.indexColorName === x.indexColorName,
              )
            : manufacture.color_list.find((y) => y.name === x.name);

          const color = {
            name: x.name, // 컬러명
            style: x.style, // 스타일 명
            quantity: x.success_quantity, // 출고 수량
            indexColorName: x.indexColorName ? x.indexColorName : "", // 가공의뢰서 color id
          };

          if (manufacture.unit_sign !== this.release.unit_sign) {
            color.quantity = (
              +color.quantity * (manufacture.unit_sign === "y" ? 1.094 : 0.914)
            ).toFixed(0);
          }

          //Surcharge (제직, 단위 당)
          if (manufacture.sucharge_price) {
            color.surcharge_price = this.decimalCacul(
              color.quantity * manufacture.surcharge_price,
            );
            purchase.surcharge_price += color.surcharge_price;
          }

          //Surcharge (염색 단위당)
          if (!manufactureColor && manufactureColor.color_surcharge_unit) {
            color.color_surcharge_unit = this.decimalCacul(
              color.quantity * manufactureColor.color_surcharge_unit,
            );
            purchase.color_surcharge_price += color.color_surcharge_unit;
          }

          if (
            x.color_surcharge_price ||
            (!manufactureColor &&
              manufactureColor.color_surcharge_price &&
              !manufactureColor.color_surcharge_price.is_used &&
              color.quantity)
          ) {
            color.color_surcharge_price =
              manufactureColor.color_surcharge_price.price;
            purchase.color_surcharge_price += color.color_surcharge_price;
            manufactureColor.color_surcharge_price.is_used = true;
          }

          color.total_amount = this.decimalCacul(
            +color.quantity * purchase.unit_price,
          );

          purchase.total_amount += color.total_amount;
          purchase.color_list.push(color);
        }
      });

      purchase.total_amount +=
        purchase.surcharge_price + purchase.color_surcharge_price;
      // purchase.total_amount = Math.round(purchase.total_amount);
      purchase.quantity = purchase.color_list.reduce(
        (r, c) => r + +c.quantity,
        0,
      );
      if (manufacture.eschamge) {
        purchase.eschamge = manufacture.eschamge;
        purchase.eschamge_price = purchase.total_amount * manufacture.eschamge;
      }
      this.$store.commit("setReleaseColorList", {
        style: manufacture.style,
        color: manufacture.color_list,
      });

      this.purchaseDecimalCacul(purchase);
      return purchase;
    },

    purchaseDecimalCacul(purchase) {
      purchase.color_surcharge_price = this.decimalCacul(
        purchase.color_surcharge_price,
      );
      purchase.quantity = this.decimalCacul(purchase.quantity);
      purchase.surcharge_price = this.decimalCacul(purchase.surcharge_price);
      purchase.eschamge_price = this.decimalCacul(purchase.eschamge_price);
      purchase.total_amount = this.decimalCacul(purchase.total_amount);
    },

    //////////////////////////////////////////////////////////////////////////////////////////////

    salesDecimalCacul(sales) {
      sales.quantity = this.decimalCacul(sales.quantity);
      sales.eschamge_price = this.decimalCacul(sales.eschamge_price);
      sales.total_amount = this.decimalCacul(sales.total_amount);
    },

    //////////////////////////////////////// 출고의로시 기타 기능 /////////////////////////////////////

    tabMove(target, index) {
      //리팩토링 예정 임시
      // const targetMap = {
      //   1: "inputTab1",
      //   2: "inputTab2",
      //   3: "inputTab3",
      //   4: "inputTab4",
      //   5: "inputTab5",
      //   6: "inputTab6",
      //   7: "inputTab7",
      //   8: "inputTab8",
      //   9: "inputTab9",
      //   10: "inputTab10_0",
      //   11: "inputTab11_0",
      //   12: "inputTab12_0",
      //   13: "inputTab13_0",
      // };
      // if (target.key === "Enter") {
      //   this.$refs[targetMap[index]]
      //     ? (this.$refs[targetMap[index]].isFocused = false)
      //     : "";
      //   this.$refs[targetMap[index]].$parent
      //     ? this.$refs[targetMap[index]].$parent.isActive
      //       ? (this.$refs[targetMap[index]].$parent.isActive = false)
      //       : ""
      //     : "";
      //   this.$refs[targetMap[index + 1]]
      //     ? Array.isArray(this.$refs[targetMap[index + 1]])
      //       ? this.$refs[targetMap[index + 1]][0].focus()
      //       : this.$refs[targetMap[index + 1]].focus()
      //     : this.$refs[targetMap[1]].focus();
      //   this.$refs[targetMap[index + 1]].$parent
      //     ? this.$refs[targetMap[index + 1]].$parent.isActive
      //       ? ""
      //       : this.$refs[targetMap[index + 1]].activateMenu
      //       ? this.$refs[targetMap[index + 1]].activateMenu()
      //       : (this.$refs[targetMap[index + 1]].$parent.isActive = true)
      //     : "";
      // }
    },
    //신규 다이얼로그 오픈
    checkNewSettingForm(e, kind, refs) {
      if (e.key !== "Enter") return;
      const editedItem = {};
      editedItem.doc = "release";
      this.$store.commit("setEditedItemForm");
      const input =
        typeof e.target.value === "string" && e.target.value !== null
          ? e.target.value.trim().toLowerCase()
          : e.target.value.name.trim().toLowerCase();
      if (
        kind === "place_forward" ||
        kind === "place_receive" ||
        kind === "place_sales"
      ) {
        if (!this.clientList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.name_only = input;
          editedItem.target = kind;
          editedItem.type = "Client";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
        }
      }
      if (kind === "item") {
        if (!this.itemList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.unit_price_sign = "₩";
          editedItem.unit_length = "y";
          editedItem.unit_width = "in";
          editedItem.unit_weight = "g/y";
          editedItem.inspect_status = true;
          editedItem.name = input;
          editedItem.target = kind;
          editedItem.type = "Item";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
        }
      }
    },

    //연필버튼 동작
    openEditDialog(item, target, type) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };

      this.editedItem.type = type;
      if (type === "Item")
        this.editedItem.production = this.editedItem.production
          ? this.clientList.find((x) => x._id === this.editedItem.production)
          : "";
      this.editedItem.company = this.$store.state.auth_company;
      this.editedItem.business_num
        ? (this.editedItem.business_num = this.getBusinessNumMask(
            this.editedItem.business_num,
          ))
        : "";
      this.editedItem.unit_price = this.editedItem.unit_price
        ? this.usingComma(this.editedItem.unit_price)
        : "";
      this.editedItem.tel
        ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
        : "";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },

    //재고 item set
    setReleaseStockItem() {
      this.setStockItems();
      this.stockItemChange();
    },
  },

  created() {
    if (this.release.id && this.release.requestDoc.kind === 17)
      this.setReleaseStockItem();
  },
};
