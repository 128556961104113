import { mapGetters } from "vuex";

// production index js

import orderListHeader from "@/assets/table/order/orderListHeader";
import productionOrderHeaders from "@/assets/table/production/productionOrderHeaders";

//components import
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import OrderListForm from "@/components/organisms/FilterList/productionOrderList.vue";

// import FabricDocTopBtn from "@/components/pages/production/greigeFabric/FabricDocTopBtn.vue";
import ClearCheckDialog from "@/components/pages/production/ClearCheckDialog.vue";
import ApprovalDialog from "@/components/pages/production/ApprovalDialog.vue";
import BakDocDialog from "@/components/organisms/VDialog/BakDocDialog.vue";

import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import CancelDialog from "@/components/pages/production/CancelDialog.vue";
import fileUploadDialog from "@/components/organisms/VDialog/fileUpload";
import FabricCaculation from "@/components/organisms/VDialog/FabricCaculation.vue";
import PreivewDialog from "@/components/organisms/VDialog/previewDialog.vue";
import Progress from "@/components/pages/production/progress/Progress.vue";
import DocForm from "@/components/pages/production/docForm/DocForm.vue";

import { getClientByCompany, postClient, putClient } from "@/api/client";
import { postItem, putItem } from "@/api/item";

export default {
  components: {
    LoadingSpinner,
    OrderListForm,

    // RightTopBtn,
    // ManufactureForm,
    // ReleaseForm,
    // AppearanceForm,
    ApprovalDialog,
    BakDocDialog,
    ClearCheckDialog,
    PreivewDialog,
    // FabricDocTopBtn,
    FabricCaculation,
    printDialog: () => import("@/components/organisms/VDialog/PrintDialog.vue"),
    SnackBar,
    CancelDialog,
    fileUploadDialog,
    Progress,
    DocForm,
  },
  data() {
    return {
      //테이블 헤더 폼
      orderListHeader,
      productionOrderHeaders,

      company: {},

      // orderList: [],
      purchaseCompanyList: [],
      salesCompanyList: [],
      clientCompanyList: [],
      inspectionList: [],
      companyList: [],
      //전화번호,사업자번호 파싱용
      viewTel: "",
      viewBusinessNum: "",

      //왼쪽 v-data-table select And search

      tableLiaisonSelect: [],
      tableBuyerSelect: [],
      tableItemNoSelect: [],
      tableNameProductionSelect: [],
      tableProductionSelect: [],

      //목록에없는 생산처, 가공처 등 생성 변수
      editedItem: {},
      dialog_client: false,
      dialog_item: false,
      dialogTitle: "",
      kind_disable: true,
      styleItems: [],
      styleItem: "",
      styleSelect: true,
      inspectStatusText: "검사포함",
      headersHistory: [
        {
          text: "날짜",
          width: "40%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "내용",
          width: "70%",
          align: "center",
          class: "tableHeaderRigth",
        },
      ],
      numsFilters: {
        order_front_year: [],
        order_date: [],
        order_front_season: [],
        order: [],
        client: [],
        itemNo: [],
        place: [],
        placeItemNo: [],
        master: [],
        status: [],
      },
      filters: {
        order_front_year: [],
        order_date: [],
        order_front_season: [],
        order: [],
        client: [],
        itemNo: [],
        place: [],
        placeItemNo: [],
        master: [],
        status: [],
      },
      purposeFilters: {
        order_front_year: ["order_front_year"],
        order_date: ["order_date"],
        order_front_season: ["order_front_season"],
        order: ["order"],
        client: ["client", "name"],
        itemNo: ["item", "name"],
        place: ["productionItem"],
        placeItemNo: ["item", "production_item"],
        master: ["liaison", "name"],
        status: ["status"],
      },
      activeFilters: {
        order_front_year: [],
        order_date: [],
        order_front_season: [],
        order: [],
        client: [],
        itemNo: [],
        place: [],
        placeItemNo: [],
        master: [],
        status: [],
      },
      productionLogHeader: [
        {
          text: "",
          value: "check",
          width: "5%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "날짜",
          value: "date_modify",
          width: "5%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "작업",
          value: "log_detail",
          width: "10%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "내용",
          value: "detail",
          width: "20%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "담당자",
          value: "date_payment",
          width: "5%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "상태",
          value: "status",
          width: "5%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "특이사항",
          value: "special_note",
          width: "20%",
          align: "center",
          class: "tableHeaderRigth",
        },
      ],
      mookupList: [],
      mookupDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      itemList: "getItemList",
      clientList: "getClientList",
      orderList: "getProductionOrderList",
    }),

    formAppearanceDisabled() {
      return this.appearance.status === "00" ? true : false;
    },
    titleManufactureSave() {
      return this.$store.state.auth_grade >= 2
        ? "가공의뢰서 승인"
        : "가공의뢰서 승인 요청";
    },
  },
  methods: {
    async getCompanyList() {
      try {
        const companyList = await getClientByCompany(
          this.$store.state.auth_company,
        );
        if (companyList.status !== 200) throw `error : ${companyList.status}`;
        this.companyList = companyList.data;
        this.purchaseCompanyList = [];
        this.salesCompanyList = [];
        this.clientCompanyList = [];
        this.inspectionList = [];
        this.receiveList = [];

        //신규 클라이언트 수정이나 생성시 값 넣어주는 부분 추후 변경예정
        if (this.editedItem.kind === "inspection") {
          this.release.inspection = this.inspectionList.find(
            (x) => x.name === this.editedItem.name,
          );
          this.manufacture.inspection = this.companyList.find(
            (x) => x.name === this.editedItem.name,
          );
        } else {
          // this.release.place_receive = this.companyList.find(
          //   (x) => x.name === this.editedItem.name,
          // );
          this.release.place_forward = this.companyList.find(
            (x) => x.name === this.editedItem.name,
          );
          this.manufacture.place_manufacture = this.companyList.find(
            (x) => x.name === this.editedItem.name,
          );
        }
      } catch (e) {
        alert(e);
      }
    },

    closeEditItemForm() {
      this.dialog_item = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeEditClientForm() {
      this.dialog_client = false;
      this.kind_disable = true;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    openEditDialog(item, type) {
      switch (type) {
        case "client":
          this.dialogTitle = "Edit Client";
          this.editedItem = { ...item };
          this.editedItem.number
            ? (this.editedItem.number = this.getBusinessNumMask(
                this.editedItem.number,
              ))
            : "";
          this.editedItem.tel
            ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
            : "";

          this.kind_disable = false;
          this.dialog_client = true;
          break;
        case "inspection":
          this.dialogTitle = "Edit Client";
          this.editedItem = { ...item };

          this.editedItem.number
            ? (this.editedItem.number = this.getBusinessNumMask(
                this.editedItem.number,
              ))
            : "";
          this.editedItem.tel
            ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
            : "";
          this.kind_disable = true;
          this.dialog_client = true;
          break;
        case "item":
          this.dialogTitle = "Edit Item";
          this.editedItem = { ...item };
          this.editedItem.production
            ? (this.editedItem.production = this.companyList.find(
                (x) => x._id === item.production,
              ))
            : "";
          this.editForm.unit_price = this.editedItem.unit_price
            ? this.usingComma(this.editedItem.unit_price)
            : "";
          this.dialog_item = true;
          break;
        default:
          break;
      }
    },
    async saveItemForm() {
      if (!this.editedItem.name) {
        this.snackbar_text = `아이템명을 입력해주세요.`;
        this.snackbar = true;
        return;
      }

      if (!this.editedItem.production) {
        this.snackbar_text = `생산처를 선택해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.editedItem.name_production) {
        this.snackbar_text = `아이템명(생산처)를 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      await this.postItem();
      await this.getItemList();
      this.snackbar_text = `Save Complete : ${this.editedItem.name}`;
      this.snackbar = true;
      this.closeEditItemForm();
    },
    async saveClientForm() {
      if (!this.editedItem.kind) {
        this.snackbar_text = `거래처 종류를 선택해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.editedItem.name) {
        this.snackbar_text = `회사명을 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      await this.postClient();
      await this.getCompanyList();

      this.snackbar_text = `Save Complete : ${this.editedItem.name}`;
      this.snackbar = true;
      this.closeEditClientForm();
    },
    async postClient() {
      try {
        this.editedItem.company = this.$store.state.auth_company;
        this.editedItem.tel
          ? (this.editedItem.tel = this.editedItem.tel.replace(/[^0-9]/g, ""))
          : "";
        this.editedItem.number
          ? (this.editedItem.number = this.editedItem.number.replace(
              /[^0-9]/g,
              "",
            ))
          : "";
        const result = this.editedItem._id
          ? await putClient(this.editedItem)
          : await postClient(this.editedItem);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
    async postItem() {
      this.editedItem.company = this.$store.state.auth_company;
      try {
        const result = this.editedItem._id
          ? await putItem(this.editedItem)
          : await postItem(this.editedItem);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },

    clearAll(col) {
      this.filters[col] = [];
    },
    itemWidthCheck() {
      if (
        this.editedItem.width !== "" &&
        this.editedItem.full_width !== "" &&
        +this.editedItem.width > +this.editedItem.full_width
      ) {
        this.snackbar_text = `가용 폭은 전체 폭을 초과할 수 없습니다.`;
        this.snackbar = true;
        this.editedItem.width = "";
      }
    },
    //목업용 함수
  },
  // sockets: {
  //   async order(data) {
  //     if (this.$store.state.auth_company === data.company) {
  //       await this.$store.dispatch("PARSE_ROUTE_API_DATA_CALL");

  //       // await this.$store.dispatch(
  //       //   "SET_PRODUCTION_ORDER",
  //       //   this.$store.state.saveData,
  //       // );
  //     }
  //   },
  // },
  async created() {
    // this.$store.dispatch("SET_USERLIST");
    await this.$store.dispatch("GET_COMPANY");
    // this.$store.dispatch("SET_CLIENTLIST");
    // this.$store.dispatch("SET_USE_CLIENTLIST");
    // this.$store.dispatch("SET_API_LOADING", false);
  },
  mounted() {},
  beforeDestroy() {
    if (this.$refs.printDialog) this.$refs.printDialog.$destroy();
  },
};
